.EditMovieModal { 
  .EditMovieModal-inner {
    padding: 20px; 
    overflow: auto;
    height: 75vh;
    width: calc(100vw - 20px);

    .EditMovieModal-field-label {
      font-weight: bold;
      font-size: 1rem;
      display: inline-block;
      margin-top: 20px;
      margin-right: 10px;
    }

    .BaseDatePickerList .Inner {
      width: 100%;
    }

    .EditMovieModal-trailer {
      display: flex;
      flex-direction: column;
    } 
  }

  .Actions {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}