@import "../../../../../../../../../variables";

.BannerCard {
  height: fit-content;

  .ant-card-head {
    .BannerCard-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;

      .Title {
        flex: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .BaseCard-body-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .FooterWrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .Enabled {
        cursor: default;
        background-color: $movie-enabled !important;
        color: $white !important;
      }

      .Disabled {
        cursor: default;
        background-color: $movie-offline !important;
        color: $white !important;
      }

      .Delete {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .Checkbox {
         .ant-checkbox {
           border: 1px solid $purple-light;
           border-radius: 2px;
         }
        }
      }
    }
  }

  .ant-card-extra {
    i { 
      font-size: 24px; 
    }
  }

  .ant-card-body {

    .BaseCard-body {
      width: 100%;
    }

    .BaseCard-body-children {
      padding: 15px !important;

      .BannerCard-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .BaseCard-body-children {
          flex: 1;
          min-height: 0;
        }

        .ImageComponent {
          width: 100%;
          height: auto;
          cursor: pointer;

          &.HasVideoClip {
            border-color: #e50000;
          }

          img {
            object-fit: contain;
            width: 100%;
            margin-top: 10px;
            max-height: 110px;
          }
        }
      }
    }

    .BannerCard-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;

      .ant-btn {
        border-color: $purple-light;
        margin: 10px 10px 0;
        font-weight: bold;
      }

      .LinkButton {
        margin: 10px 10px 0;
      }

      a {
        font-weight: bold;
      }
    }

    @media (min-width: 1000px) {
      min-height: 213px;

      .BaseCard-body {
        min-height: 213px;
      }

      .BaseCard-body-children {
        .BannerCard-body {
          flex-direction: row;

          .ImageComponent {
            height: 100%;

            img {
              margin-top: 0;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .BaseCard-body-footer {
    border-top-width: 0 !important;
    padding-top: 0 !important;
  }

  .Statuses {
    display: none;

    .BannerState {
      display: inline-block; 
    }

    i {
      margin-right: 5px; 
      font-size: 24px; 

      &.fa-film {
        color: $blue-lighter;
      }
    }

    &.Bottom {
      display: inline-block;
      text-align: right;
    }

    @media (min-width: 1000px) {
      &.Top {
        display: inline-block;

        .BannerState {
          position: relative;
          padding: 3px; 
        }
      }

      &.Bottom {
        display: none;
      }
    }
  }
}
