@import "../../../../../../../../../../variables";

.BannerDetailsInfosTab {
  display: grid;
  padding: 10px;

  .ImageComponent {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    border: none;
       
    img {
      max-height: 440px !important;
    }
  } 

  .BaseCard {
    margin-bottom: 10px;

    .ant-card-head {
      color: $purple-light;
      font-weight: bold;
    }

    .BaseCard-body-children {
      padding: 0;

      .ant-table {

        .Url-Container {
          a {
            text-decoration: underline;
          }
        }

        table {
          border: none;

          tr {

            .Enabled, .Disabled {
              color: $white;
              font-weight: bold;
              padding: 5px 10px;
            }

            .Enabled {
              background-color: $banner-enabled;
            }

            .Disabled {
              background-color: $banner-disabled;
            }

            td:first-child {
              border-left-width: 0;
            }

            td:last-child {
              border-right-width: 0;
              width: 150px;
            }

            @media (min-width: 1000px) {

              td:last-child {
                width: calc(100vh - 300px);
              }
            }

            .ant-calendar-picker, .ant-select {
              width: 100%;
            }

            .ColorBox {
              display: inline-block;
              width: 100%;
              max-width: 3em;
              height: 1.5em;
              margin-right: 10px;
              border-radius: 3px;
              vertical-align: bottom;
            }
          }

          tr:last-child {
            td {
              border-bottom-width: 0;
            }
          }
        }

        .ant-table-footer {
          padding: 10px;

          .InfoTableFooter {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .ant-btn {
              border-color: $rebeca-purple;
              width: fit-content;
              font-size: 18px;

              &:hover {
                color: $rebeca-purple;
              }
            }
          }
        }
      }
    }
  }

  .BaseCard:last-child {
    margin-bottom: 0;
  }
}
